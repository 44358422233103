import React, { useState } from 'react';
import styled from 'styled-components';

// Importando os componentes
import ComponentOne from './Contador.js';  
import ComponentTwo from './Contatos.js';  
import ComponentThree from './resultados.js';
import LogoDash from '../../assets/logodash.png';
import Sair from '../../assets/sair.png';

// Estilizando o Dashboard
const DashboardContainer = styled.div`
  display: flex;
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
`;

const SidebarItem = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  text-align: left;
  background-color: ${props => (props.active ? '#444' : '#333')};
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #555;
  }
`;

const ContentArea = styled.div`
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
  background-color: #212121;
`;

const Imagem = styled.img`
  width: 100%;
`;

const Div = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 10px;
  margin-top: 40px;

  a{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 10px;
    margin-top: 40px;
    text-decoration: none;
    color: white;
  }

  img{
    width: 30px;
    margin-right: 10px;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
`;

const LoginForm = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #444;
  }
`;

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState('component1');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  const handleSwitch = (component) => {
    setActiveComponent(component);
  };

  const handleLogin = () => {
    if (password === 'Sirius1010@') {
      setIsAuthenticated(true);
    } else {
      alert('Senha incorreta');
    }
  };

  if (!isAuthenticated) {
    return (
      <LoginContainer>
        <LoginForm>
          <h2>Login</h2>
          <LoginInput 
            type="password" 
            placeholder="Digite a senha" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
          <LoginButton onClick={handleLogin}>Entrar</LoginButton>
        </LoginForm>
      </LoginContainer>
    );
  }

  return (
    <DashboardContainer>
      <Sidebar>
        <Imagem src={LogoDash} />
        <SidebarItem 
          active={activeComponent === 'component1'} 
          onClick={() => handleSwitch('component1')}
        >
          Visualização
        </SidebarItem>
        <SidebarItem 
          active={activeComponent === 'component2'} 
          onClick={() => handleSwitch('component2')}
        >
          Contatos
        </SidebarItem>
        <SidebarItem 
          active={activeComponent === 'component3'} 
          onClick={() => handleSwitch('component3')}
        >
          Atualizar Resultados
        </SidebarItem>
        <Div>
          <a href='/'>
            <img src={Sair} />
            <h2>Sair</h2>
          </a>
        </Div>
      </Sidebar>

      <ContentArea>
        {activeComponent === 'component1' && <ComponentOne />}
        {activeComponent === 'component2' && <ComponentTwo />}
        {activeComponent === 'component3' && <ComponentThree />}
      </ContentArea>
    </DashboardContainer>
  );
};

export default Dashboard;
