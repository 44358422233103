import React from 'react';
import styled from 'styled-components';
import Video1 from '../../assets/videoapp.mp4';
import IconeApple from '../../assets/apple.png';
import IconeGoogle from '../../assets/google.png';
import { useTranslation } from 'react-i18next';

// Container principal para organizar as sections
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #171717;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

// Section do lado esquerdo
const LeftSection = styled.section`
  max-width: 500px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

// Section do lado direito (onde o vídeo ficará)
const RightSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Título
const Title = styled.h1`
  font-size: 2.5rem;
  color: white;
  margin-bottom: 10px;
`;

// Subtítulo
const SubTitle = styled.p`
  font-size: 1.5rem;
  color: #c3c3c3;
  margin-bottom: 20px;
`;

// Botões em linha
const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  width: 600px;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

// Estilo dos botões
const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 1rem;
  color: black;
  font-weight: 700;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;

  
  &:hover {
    filter: brightness(0.8);
  }
`;

const Foto = styled.img`
  width: 50px;

`;

const TitleButton = styled.h1`

`;

const Video = styled.video`
  max-width: 700px;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const VideoSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <LeftSection>
        <Title>{t('title.app.sirius')}</Title>
        <SubTitle>{t('sub.app.sirius')}</SubTitle>
        <ButtonRow>
          <Button target='_blank' href='https://apps.apple.com/us/app/sirius-robot/id6738701118?uo=2'>
            <TitleButton>Apple Store</TitleButton>
            <Foto src={IconeApple}/>
          </Button>
          <Button href='/android-app'>
            <TitleButton>Android App</TitleButton>
            <Foto src={IconeGoogle}/>
          </Button>
        </ButtonRow>
      </LeftSection>
      <RightSection>
        <Video loop muted autoPlay>
          <source src={Video1} type="video/mp4" />
        </Video>
      </RightSection>
    </Container>
  );
};


export default VideoSection;
