import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconX from '../../assets/X.png';
import { Link } from 'react-router-dom';
import Skilling01 from "./part2/1.webp";
import Skilling02 from "./part2/2.webp";
import Skilling03 from "./part2/3.webp";
import Skilling04 from "./part2/4.webp";
import Skilling05 from "./part2/5.webp";

import Header from "../Header/Header2.js";


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const StyledLink2 = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;


const TitleSub = styled.h1`
  text-align: left;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 26rem;

  @media (max-width: 1000px) {
    margin-left: 1rem;
  }
`;


const TitleSub1 = styled.h1`
  text-align: left;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 1rem;

  @media (max-width: 1000px) {
    margin-left: 1rem;
  }
`;

const Card = styled.div`
  width: 1080px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Card2 = styled.div`
  width: 670px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;

  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.a`
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  cursor: pointer;
  font-weight: 600;
  color: black;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    filter: brightness(0.8);
  }
`;

const Image = styled.img`
  max-width: 600px;
  margin: 20px;

  @media (max-width: 1000px) {
    max-width: 600px;
    width: 100%;
  }
`;


const TextPrincipal = styled.h1`
  color: transparent;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const SubPrincipal2 = styled.p`
  color: white;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const Linkada = styled.a`
  color: transparent;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;

  &:hover{
    filter: brightness(0.8);
  }
`;

function Site() {
    const { t } = useTranslation();

    
    return (
        <Container>
          <Header/>
            <StyledLink to='/infinox-2'>
                <Fechar src={IconX} alt="Fechar"/>   
            </StyledLink>
            <Card>
                <SubPrincipal2>Como fazer o seu depósito em sua conta Infinox</SubPrincipal2>
                
                <TitleSub>- Acesse a sua carteira e clique em "Depósito"</TitleSub>
                
                <Card2>
                <Image src={Skilling01}  />
                <TitleSub1>- Escolha a opção marcada na imagem abaixo e depois clique em " Carregue para depositar"
                </TitleSub1>
                </Card2>

                <Image src={Skilling02}  />

                <TitleSub>- Dentre as opções escolha " Pix"</TitleSub>

                <Card2>
                <Image src={Skilling03}  />
                <TitleSub1>- No passo seguinte preencha todas as informações:</TitleSub1>
                <br></br>

                <TitleSub1>- Document Number(CPF): Número do seu CPF</TitleSub1>
                <TitleSub1>- State: O seu Estado</TitleSub1>
                <TitleSub1>- Montante: Valor em dólar que deseja depositar</TitleSub1>
                <br></br>
                <TitleSub1>- Após preencher todas as informações clique em " Depósito "</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling04}  />
                <TitleSub1>- Na nova janela de pagamento é só scanear o QR code com o app do seu banco e efetuar o pagamento</TitleSub1>
                <TitleSub1>Após efetuar o pagamento clique em "Pagamento realizado"</TitleSub1>

                </Card2>

                <Card2>  
                <Image src={Skilling05}  />
                <TitleSub1>- Confira todos os seus dados e o valor a ser pago,
                Se estiver tudo certo clique em "Confirmar"</TitleSub1>
                <Botao href='/infinox-assinatura-2'>Agora vamos para a assinatura do contrato, clique aqui</Botao>
                </Card2>

           </Card>
        </Container>
    );
}

export default Site;
