import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../Resultados/supabaseClient.js'; // Certifique-se de usar o caminho correto
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate
import { toZonedTime, toDate } from 'date-fns-tz';


const ListWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #212121;
  border-radius: 8px;
  color: white;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #111111;
  color: white;
  min-height: 100vh;
  padding: 20px;
  border-radius: 20px;

  h1{
    text-align: center;
    margin: 14px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 5px;
  background: #222;
  color: white;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  color: #111;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    filter: brightness(0.8);
  }
`;

const EditPage = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [data, setData] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate(); // Hook para navegação
  const CORRECT_PASSWORD = 'sirius1010@'; // Substitua por sua senha desejada

  useEffect(() => {
    if (authenticated) {
      // Fetch initial data apenas se autenticado
      const fetchData = async () => {
        const { data: result, error } = await supabase
          .from('sirius') // Substitua pelo nome da sua tabela
          .select('*')
          .eq('id', 1); // Buscar pelo ID 1

        if (error) {
          console.error('Erro ao buscar dados:', error);
        } else {
          setData(result[0]); // Como só tem um resultado, pegue o primeiro item
          reset(result[0]); // Preenche o formulário com os dados atuais
        }
      };

      fetchData();
    }
  }, [authenticated, reset]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === CORRECT_PASSWORD) {
      setAuthenticated(true);
    } else {
      alert('Senha incorreta!');
    }
  };

  const onSubmit = async (formData) => {
    // Data atual no fuso horário de Brasília
    const timezone = 'America/Sao_Paulo'; // Fuso horário de Brasília
    const currentDate = new Date();
    
    // Converte para o horário de Brasília
    const brDate = toZonedTime(currentDate, timezone); 
  
    // Converte a data para UTC (sem perda de informações)
    const utcDate = toDate(brDate); // A data já está ajustada para o fuso horário correto
  
    // Adicionando a data ajustada ao formData
    const updatedFormData = {
      ...formData,
      updated_at: utcDate.toISOString(), // Converte para o formato ISO 8601
    };
  
    const { error } = await supabase
      .from('sirius')
      .update(updatedFormData)
      .eq('id', 1); // Atualizar pelo ID 1
  
    if (error) {
      console.error('Erro ao atualizar dados:', error);
    } else {
      alert('Dados atualizados com sucesso!');
      setData(updatedFormData); // Atualiza os dados locais
  
      // Redireciona para a página principal
      navigate('/'); // Caminho para a página principal
    }
  };
  
  

 
  return (
    <ListWrapper>
    <Container>
      <h1>Editar Dados Myfxbook <br/> Os dados são enviados para o aplicativo.</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>

        <Label htmlFor="diariamente">Diariamente:</Label>
        <Input id="diariamente" {...register('diariamente')} />

        <Label htmlFor="mensalmente">Mensalmente:</Label>
        <Input id="mensalmente" {...register('mensalmente')} />

        <Button type="submit">Enviar</Button>
      </Form>
    </Container>
    </ListWrapper>
  );
};

export default EditPage;
