import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconX from '../../assets/X.png';
import { Link } from 'react-router-dom';
import Skilling01 from "./part3/1.webp";
import Skilling02 from "./part3/2.webp";
import Skilling03 from "./part3/3.webp";
import Skilling04 from "./part3/4.webp";
import Skilling05 from "./part3/5.webp";
import Skilling06 from "./part3/6.webp";

import Header from "../Header/Header2.js";


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const StyledLink2 = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;


const TitleSub = styled.h1`
  text-align: left;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 26rem;

  @media (max-width: 1000px) {
    margin-left: 1rem;
  }
`;


const TitleSub1 = styled.h1`
  text-align: left;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-left: 1rem;

  @media (max-width: 1000px) {
    margin-left: 1rem;
  }
`;

const Card = styled.div`
  width: 1080px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Card2 = styled.div`
  width: 670px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;

  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.a`
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  cursor: pointer;
  font-weight: 600;
  color: black;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    filter: brightness(0.8);
  }
`;

const Image = styled.img`
  max-width: 600px;
  margin: 20px;

  @media (max-width: 1000px) {
    max-width: 600px;
    width: 100%;
  }
`;


const TextPrincipal = styled.h1`
  color: transparent;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const SubPrincipal2 = styled.p`
  color: white;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: center;
`;

const Linkada = styled.a`
  color: transparent;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;

  &:hover{
    filter: brightness(0.8);
  }
`;

function Site() {
    const { t } = useTranslation();

    
    return (
        <Container>
          <Header/>
            <StyledLink to='/infinox-deposito-2'>
                <Fechar src={IconX} alt="Fechar"/>   
            </StyledLink>
            <Card>
                <SubPrincipal2>Como assinar o contrato para habilitação de conta
                <br/>(Conexão conta cliente com a Rise)</SubPrincipal2>

                <Card2>
                <TitleSub1>Acesse o seu e-mail, você receberá um e-mail da equipe Infinox onde
                o assunto será igual a imagem abaixo, clique em "Review Documents</TitleSub1>
                </Card2>

                <Card2>
                <Image src={Skilling01}  />
                <TitleSub1>Abrirá uma nova aba em seu navegador, clique na caixinha "Concordo***" em seguida clique em "Continuar"</TitleSub1>
                </Card2>


                <Card2>
                <Image src={Skilling02}  />
                <TitleSub1>No passo seguinte clique em "Assinar" em seguida selecione o seu nome</TitleSub1>
                </Card2>

                <Card2>
                <Image src={Skilling03}  />
                <TitleSub1>Após ter assinado ficará igual a imagem abaixo</TitleSub1>
               
                </Card2>

                <Card2>  
                <Image src={Skilling04}  />
                <TitleSub1>Em seguida clique em "Concluir"</TitleSub1> 
                </Card2>

                <Card2>  
                <Image src={Skilling05}  />
                <TitleSub1>Pronto o seu documento foi assinado, agora é só aguardar 
                o e-mail da infinox confirmando a finalização da sua conta!!!</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling06}  />
                <TextPrincipal>ATENÇÃO!!!</TextPrincipal>
                <TitleSub1>Realizado o envio do contrato devidamente assinado a corretora Infinox, o prazo de conexão da sua conta com a Rise é de 1 dia útil. 
                Só acompanhar os seus rendimentos pelo portal da Infinox!!</TitleSub1>
                </Card2>

           </Card>
        </Container>
    );
}

export default Site;
