import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Make sure to import BrowserRouter

import Home from './Components/Home/Home.js';
import HomeEN from './Components/Home/Homeen.js';
import HomeES from './Components/Home/Homees.js';
import HomeFR from './Components/Home/Homefr.js';
import HomePT from './Components/Home/Homept.js';
import HomeAR from './Components/Home/Homear.js';
import HomeCN from './Components/Home/Homecn.js';
import SuperContato from './Components/Contato/index.js';
import Skiling from './Components/Skiling/Skiling.js';
import SkilingMT4 from './Components/Skiling/MT4.js';
import DepositoSklling from './Components/Skiling/DepositoSklling.js';
import Infinoxen from './Components/Infinox/Infinox-en.js';
import Infinox2 from './Components/Infinox2/Infinox2.js';
import Infinox3 from './Components/Infinox2/Infinox3.js';
import Infinox4 from './Components/Infinox2/Infinox4.js';
import DepositoInfinox from './Components/Infinox/depositoInfinox.js';
import Pamm from './Components/Infinox/pamm.js';
import PammConservador from './Components/Infinox/pamm-conservador.js';
import PammAgressivo from './Components/Infinox/pamm-agressivo.js';
import Ganhos from './Components/Infinox/ganhos.js';
import FaqInfinox from './Components/Infinox/Faq-Infinox.js';
import FourXC from './Components/4XC/4XC.js';
import FourXCInvest from './Components/4XC/Investir4xc.js';
import PDFViewer from './Components/4XC/PDFViewer.js';
import Investir4xc from './Components/4XC/investir-sirius.js';
import Politica from './Components/Politica/Politica.js';
import Resultados from './Components/Resultados/resultados.js';
import Copy from './Components/Resultados/Copy.js';
import Android from './Components/Resultados/Android.js';

export default function Rotas() {
  return (
    <Router> {/* Wrap your Routes in a Router component */}
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/en" element={<HomeEN />} />
          <Route path="/es" element={<HomeES />} />
          <Route path="/fr" element={<HomeFR />} />
          <Route path="/pt" element={<HomePT />} />
          <Route path="/ar" element={<HomeAR />} />
          <Route path="/cn" element={<HomeCN />} />
          <Route path="/contato" element={<SuperContato/>} />
          <Route path="/abertura-skilling" element={<Skiling/>} />
          <Route path="/infinox" element={<Infinoxen/>} />
          <Route path="/deposito-infinox" element={<DepositoInfinox/>} />
          <Route path="/vincular-conta-pamm" element={<Pamm/>} />
          <Route path="/mt4-skiling" element={<SkilingMT4/>} />
          <Route path="/deposito-sklling" element={<DepositoSklling/>} />
          <Route path="/ganhos-infinox" element={<Ganhos/>} />
          <Route path="/faq-infinox" element={<FaqInfinox/>} />
          <Route path="/4XC" element={<FourXC/>} />
          <Route path="/deposito-4XC" element={<FourXCInvest/>} />
          <Route path="/4xc-beneficios" element={<PDFViewer/>} />
          <Route path="/investir-4XC" element={<Investir4xc/>} />
          <Route path="/politica-de-privacidade" element={<Politica/>} />
          <Route path="/resultados-sirius" element={<Resultados/>} />
          <Route path="/dashboard" element={<Copy/>} />
          <Route path="/android-app" element={<Android/>} />
        </Routes>
      </div>
    </Router>
  );
}
