import React, { useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconX from '../../assets/X.png';
import { Link } from 'react-router-dom';
import Skilling01 from "./part1/1.webp";
import Skilling02 from "./part1/2.webp";
import Skilling03 from "./part1/3.webp";
import Skilling04 from "./part1/4.webp";
import Skilling05 from "./part1/5.webp";
import Skilling06 from "./part1/6.png";
import Skilling07 from "./part1/7.webp";
import Skilling08 from "./part1/8.webp";
import Skilling09 from "./part1/9.webp";
import Header from "../Header/Header2.js";


const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
`;

const StyledLink2 = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin:1rem;
`;

const Fechar = styled.img``;

const Container = styled.div`
  background: #111111;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;


const TitleSub = styled.h1`
  text-align: left;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 4px;
  margin-left: 26rem;

  @media (max-width: 1000px) {
    margin-left: 1rem;
  }
`;


const TitleSub1 = styled.h1`
  text-align: left;
  width: 100%;
  color: #c3c3c3;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 4px;
  margin-left: 2rem;

  @media (max-width: 1000px) {
    margin-left: 1rem;
  }
`;

const Card = styled.div`
  width: 1080px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Card2 = styled.div`
  width: 700px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;

  position: relative;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;

const Botao = styled.a`
  width: 100%;
  height: 50px;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  cursor: pointer;
  font-weight: 600;
  color: black;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  transition: 500ms;
  margin: 8px;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    filter: brightness(0.8);
  }
`;

const Image = styled.img`
  max-width: 600px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    max-width: 600px;
    width: 100%;
  }
`;


const TextPrincipal = styled.h1`
  color: transparent;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;
  margin-bottom: 1rem;
`;

const SubPrincipal = styled.p`
  color: white;
  font-size: 1.2rem;
`;

const SubPrincipal2 = styled.p`
  color: white;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const Linkada = styled.a`
  color: transparent;
  margin: 0.5rem;
  transition: 500ms;
  font-size: 1.2rem;
  background: linear-gradient(to right, #bf8b3d, #fefebe, #bf8b3d);
  -webkit-background-clip: text;

  &:hover{
    filter: brightness(0.8);
  }
`;

function Site() {
    const { t } = useTranslation();

    
    return (
        <Container>
          <Header/>
            <StyledLink to={t('back.language')}>
                <Fechar src={IconX} alt="Fechar"/>   
            </StyledLink>
            <Card>
                <SubPrincipal>Como fazer o seu cadastro na corretora infinox</SubPrincipal>
                <TextPrincipal>Aviso Importante!!!</TextPrincipal>
                <Linkada href="https://br.trustpilot.com/" target="_blank">{t('trustpilot')}</Linkada>
                <SubPrincipal>Preencha as informações exatamente como está no passo a passo, caso não seguir as recomendações ou alterar alguma informação, poderá não ter sua conta conectada à Rise Robô.</SubPrincipal>
               
                <SubPrincipal>{t('infinox.site')}</SubPrincipal>
                <Linkada href="https://www.infinox.com/fsc/pt/registration/live-account?creative_id=5o5u8x8v&affid=7995PAMM" target="_blank">Infinox</Linkada>
                
                <SubPrincipal2>Preencha todas as suas informações</SubPrincipal2>
                <TitleSub>- Plataforma: Mt4</TitleSub>
                <TitleSub>- País: Brasil</TitleSub>
                <TitleSub>- Titulo: Selecione o pronome que deseja ser chamado </TitleSub>                  
                <TitleSub>- Nome: Seu primeiro nome</TitleSub>
                <TitleSub>- Sobrenome: Seu sobrenome</TitleSub>
                <TitleSub>- E-mail: Seu e-mail de login ( esse será seu e-mail principal)</TitleSub>
                <TitleSub>- Número de telemóvel: DDD + Número do seu celular</TitleSub>
                <TitleSub>- Moeda base: Selecione "USD"</TitleSub>
                <TitleSub>- Alavancagem: Selecione " 1:500"</TitleSub>
                <TitleSub>- Idioma de comunicação Preferencial: Selecione "Português"</TitleSub>
                <TitleSub>- Código do indicador: Pode deixar em branco</TitleSub>
            
                <br></br>

                <TitleSub>- Após ter preenchido todas as informações clique em "Próximo"</TitleSub>
                
                <Image src={Skilling01}  />

                <TitleSub>Na etapa seguinte complete com os dados abaixo:</TitleSub>
                <br></br>

                <TitleSub>- Nacionalidade: Selecione a sua nacionalidade</TitleSub>
                <TitleSub>- Data de nascimento: Preencha com a sua data de nascimento</TitleSub>
                <TitleSub>- Código postal: Preencha com o cep da sua rua</TitleSub>
                <TitleSub>- Nome e / ou número do edifício: Número da sua residência</TitleSub>
                <TitleSub>- Rua: Nome da sua rua</TitleSub>
                <TitleSub>- Cidade: Nome da sua cidade</TitleSub>
                <TitleSub>- Estado: Selecione o seu estado</TitleSub>
                <br></br>


                <Image src={Skilling02}  />

                <TitleSub>- Gênero: Selecione o seu gênero</TitleSub>
                <TitleSub>- Local de nascimento: País que você nasceu</TitleSub>
                <TitleSub>- Número de CPF: Preencha com o número do seu CPF</TitleSub>
                <br></br>
                <TitleSub>- Após ter preenchido todas as informações clique em "Próximo"</TitleSub>
                
                <Card2>
                <Image src={Skilling03}  />
                <TitleSub1>Na etapa seguinte complete com os dados abaixo:</TitleSub1>
                <br></br>

                <TitleSub1>- Situação de emprego: Escolha SOMENTE Empregado ou Trabalhador por conta própria</TitleSub1>
                <TitleSub1>- Qual é a sua indústria de ocupação: Selecione Indústria</TitleSub1>
                <TitleSub1>- Cargo de ocupação: Preencha sua ocupação de trabalho</TitleSub1>
                <TitleSub1>- Qual é a fonte de fundos com a qual você pretende negociar: Selecione Poupança/Investimento</TitleSub1>
                <TitleSub1>- Renda anual aproximada? (USD): Selecionar 5.001-25.000k ou alguma opção maior que esta, se aplica                </TitleSub1>
                <TitleSub1>- Montante (s) total (s) de depósito esperado (s) (USD): Escolha uma opção que aplique conhecimento e Experiência</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling04}  />
                <TitleSub1>- Quantos anos de experiência de negociação tem: Selecione a opção 1 a 2 anos ou superior se aplica</TitleSub1>
                <TitleSub1>- Quais são seus objetivos de negociação: Selecione especulação</TitleSub1>
                <TitleSub1>- Tolerância de risco: Selecione a opção  aceito uma quantia justa de flutuação no investimento inicial dos meus investimentos para obter retornos acima da média e crescimento de capital
                Feitos todos os passos clique em "Próximo"</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling05}  />
                <TitleSub1>Na etapa seguinte marque todas as caixinhas, em seguida clique em "Próximo"</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling06}  />
                <TitleSub1>Na etapa seguinte marque a opção</TitleSub1>
                <TitleSub1>" Quero ser classificado como cliente profissional" </TitleSub1>
                <TitleSub1>- Em seguida clique em "Próximo"</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling07}  />
                <TitleSub1>Na etapa seguinte marque a opção como na imagem abaixo e clique em "Completar"</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling08}  />
                <TitleSub1>Seguindo todos os passos anteriores
                sua conta estará concluída.</TitleSub1>
                </Card2>

                <Card2>  
                <Image src={Skilling09}  />
                <Botao href='/infinox-deposito-2'>Agora vamos depositar o dinheiro, clique aqui</Botao>
                </Card2>
           </Card>
        </Container>
    );
}

export default Site;
