import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { supabase } from './supabaseClient.js';
import Views from './Views.js';

const ListWrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #212121;
  border-radius: 8px;
  color: white;
`;

const Title = styled.h2`
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHead = styled.thead`
  background: linear-gradient(to right, #aa7d3c, #f2cf8d);
  color: white;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  border: 1px solid #444;
`;

const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: #2a2a2a;
  }
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #161616;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #444;
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const ViewCount = styled.p`
  font-size: 18px;
  color: #f2cf8d;
  text-align: center;
`;

const NewsletterList = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [error, setError] = useState('');
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    // Função para registrar uma view
    const registerView = async () => {
      const today = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
      const visitorKey = `visitor_${today}`; // Chave única para o dia

      // Verifica se o visitante já entrou hoje
      if (!localStorage.getItem(visitorKey)) {
        // Marca que o visitante entrou hoje
        localStorage.setItem(visitorKey, 'true');
        
        // Registra a view no Supabase
        await supabase
          .from('views') // Nome da tabela de views
          .upsert([{ date: today, count: 1 }], { onConflict: ['date'] });
      }
    };

    // Função para buscar o contador de views do dia atual
    const fetchViewCount = async () => {
      const today = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
      const { data, error } = await supabase
        .from('views')
        .select('count')
        .eq('date', today)
        .single();

      if (error) {
        console.error(error.message);
      } else {
        setViewCount(data ? data.count : 0);
      }
    };

    // Registrar view e buscar contagem de views
    registerView();
    fetchViewCount();
  }, []);

  useEffect(() => {
    const fetchSubscribers = async () => {
      setError('');
      try {
        const { data, error } = await supabase
          .from('news') // Nome da tabela no Supabase
          .select('*');

        if (error) {
          throw error;
        }

        setSubscribers(data || []);
      } catch (err) {
        setError('Erro ao carregar os dados. Tente novamente.');
        console.error(err.message);
      }
    };

    fetchSubscribers();
  }, []);

  return (
    <ListWrapper>
      <Views />
    </ListWrapper>
  );
};

export default NewsletterList;
