import React, { useEffect } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  background: #151515;
  padding: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }

`;

const Title = styled.a`
  color: #c3c3c3;
  font-size: 1rem;
  text-decoration: none;
  transition: 500ms;

  &:hover{
    color: white;
  }

  span{
    color: #FFC222;
  }
`;

const Card = styled.div`
  width: 1280px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin: 2rem;
  text-align: center;

  @media (max-width: 1000px) {
   width: 100%;
   max-width: 400px;
  }
`;




function Site() {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.fade-in-footer');
      const elementPosition = element.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (elementPosition < screenHeight) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <Container>
      <FadeIn className="fade-in-footer">
        <Card>
            <Title>© 2025 - Sirius Robot</Title>
        </Card>
      </FadeIn>
    </Container>
  );
}

export default Site;
